<template>
  <!-- 新增充值页面 -->
  <div class="makeOutInvoice page-main">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :inline="true"
      label-width="120px"
      class="page-form"
      label-position="left"
    >
      <el-form-item label="发票介质：" prop="receiveType">
        <el-radio-group v-model="ruleForm.receiveType">
          <el-radio label="00">
            电子发票
          </el-radio>
          <el-radio label="01">
            纸质发票
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发票类型：" prop="invoiceType">
        <el-radio-group v-model="ruleForm.invoiceType">
          <el-radio label="00">
            普票
          </el-radio>
          <el-radio label="01">
            专票
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发票抬头：" prop="invoiceTitleId">
        <div class="flex">
          <el-select v-model="ruleForm.invoiceTitleId" placeholder="请选择" @change="getInformation">
            <el-option v-for="item in cmpInvoiceArr" :key="item.id" :label="item.invoiceName" :value="item.id" />
          </el-select>
          <router-link :to="{ path: '/invoiceManagement/lookedUpManagement' }">
            新增抬头
          </router-link>
        </div>
      </el-form-item>
      <el-form-item label="发票抬头：">
        {{ ruleForm.invoiceName }}
      </el-form-item>
      <el-form-item label="纳税人识别号：">
        {{ ruleForm.invoiceUnicode }}
      </el-form-item>
      <el-form-item label="开户行名称：">
        {{ ruleForm.invoiceBankName }}
      </el-form-item>
      <el-form-item label="开户行账号：">
        {{ ruleForm.invoiceBankCode }}
      </el-form-item>
      <el-form-item label="公司联系电话：">
        {{ ruleForm.invoiceTel }}
      </el-form-item>
      <el-form-item label="公司地址：">
        {{ ruleForm.recAddr }}
      </el-form-item>
      <el-form-item label="发票内容：">
        <p class="link" @click="checkInvoice">
          商品明细，发票样板查看
        </p>
      </el-form-item>
      <el-form-item label="电子邮箱：">
        {{ ruleForm.recMail }}
      </el-form-item>
      <el-form-item label="联系人姓名：">
        {{ ruleForm.recName }}
      </el-form-item>
      <el-form-item label="联系人电话：">
        {{ ruleForm.recTel }}
      </el-form-item>
      <el-form-item label="开票金额(元)：">
        {{ ruleForm.invoiceAmt | numberToCurrency }}
      </el-form-item>
      <el-form-item label="申请单号：">
        {{ ruleForm.operPlat }}
      </el-form-item>
      <el-form-item class="lastOne" label="备注：">
        <el-input v-model="ruleForm.invoiceRemarks" type="textarea" maxlength="50" show-word-limit :rows="3" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button class="widen-button" type="primary" size="small" @click="submitForm('ruleForm')">
        提交
      </el-button>
      <el-button plain class="widen-button" size="small" @click="resetForm('ruleForm')">
        重置
      </el-button>
    </div>
    <invoiceTemplate ref="invoiceTemplate" :invoice-obj="ruleForm" />
  </div>
</template>

<script>
import invoiceTemplate from './invoiceTemplate.vue'
import { cmpInvoicePage } from '@/http/custApi/invoiceManagement'
import { roundUpToInteger } from '@/unit/index'
import { agentPurchaseContractInvoiceSubmit, agentPurchaseContractViewInvoiceSample } from '@/http/custApi/financialManage'
export default {
  components: { invoiceTemplate },
  data() {
    return {
      ruleForm: {
        invoiceTitleId: '',
        invoiceType: '',
        receiveType: '',
        invoiceContents: []
      },
      cmpInvoiceArr: [],
      rules: {
        invoiceTitleId: [
          { required: true, message: '请选择一个发票抬头', trigger: 'change' }
        ],
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        receiveType: [
          { required: true, message: '发票接收方式', trigger: 'change' }
        ]
      },
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/purchaseContractManage/index', name: '采购合同管理' }, { path: '', name: '开票提交' }]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id
      this.ruleForm.operPlat = this.$route.query.custContractNo
      agentPurchaseContractViewInvoiceSample(this.ruleForm.id, res => {
        this.ruleForm.taxAmount = Number(res.data.settleTotalAmount)
        this.ruleForm.money = Number(res.data.settleTaxAmount)
        this.ruleForm.invoiceAmt = roundUpToInteger(this.ruleForm.taxAmount + this.ruleForm.money)
        this.ruleForm.capitalMoney = this.digitUppercase(this.ruleForm.invoiceAmt)
        this.ruleForm.invoiceContents = [...res.data.viewInvoiceSampleList] || []
      })
    }
    cmpInvoicePage({
      pageSize: 10000,
      cmpCode: this.$getUserInfoParameter('cmpId'),
      pageNum: 1
    }, res => {
      this.cmpInvoiceArr = [...res.data.pageData]
    })
  },
  methods: {
    // 金额小写换成大写
    digitUppercase(price) {
      const fraction = ['角', '分']
      const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
      const unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟']
      ]
      let num = Math.abs(price)
      let s = ''
      fraction.forEach((item, index) => {
        s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '')
      })
      s = s || '整'
      num = Math.floor(num)
      for (let i = 0; i < unit[0].length && num > 0; i += 1) {
        let p = ''
        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
          p = digit[num % 10] + unit[1][j] + p
          num = Math.floor(num / 10)
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
      }

      return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整')
    },
    // 点击查看发票
    checkInvoice() {
      this.$refs.invoiceTemplate.visible = true
    },
    // 发票抬头选择
    getInformation() {
      // 取到相对应的值
      this.cmpInvoiceArr.forEach(item => {
        if (item.id === this.ruleForm.invoiceTitleId) {
          Object.assign(this.ruleForm, item, { id: this.ruleForm.id })
        }
      })
    },
    // 点击确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          agentPurchaseContractInvoiceSubmit(this.ruleForm, () => {
            this.$message.success('成功')
            this.$router.go(-1)
          })
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="scss">
.makeOutInvoice {
  .el-form {
    padding: 32px 0;

    .flex {
      display: flex;

      a {
        display: block;
        text-align: right;
        width: 140px;
        color: $selectColor;
        text-decoration: underline;
      }
    }
  }

  .page-form {
    padding: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;

    .el-form-item {
      width: calc(32% - 32px);
      .link{
        color: $assistColor4;
        text-decoration: underline;
        cursor: pointer;
      }
      .el-form-item__content{
        width: calc(100% - 120px);
      }
    }
    .lastOne{
      width: 96%;
    }
  }
  .buttonCombination{
    text-align: center;
    background-color: #fff;
    padding-bottom: 32px;
  }

  .page-form:after {
    content: "";
    width: 32%;
  }

  .el-select,
  .el-input,
  .el-cascader {
    width: 100%;
  }

  /deep/ .el-form-item__label {
    padding-left: 0;
  }

  .page-form .spread-over {
    width: 100%;
  }

}
</style>
